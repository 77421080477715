import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 20px; /* Réduit le padding sur mobile */
  }
`;

const Title = styled.h1`
  text-align: center;
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 700;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    font-size: 24px; /* Réduit la taille du titre sur mobile */
  }
`;

const SearchBarContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 80%;
  margin: 0 auto 40px auto;

  @media (max-width: 768px) {
    width: 90%; /* Augmente la largeur sur mobile */
  }
`;

const Input = styled.input`
  padding: 15px 20px;
  border: 1px solid #d9e1e8;
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  width: 100%;
  background-color: #f2f6f6;
  box-sizing: border-box;

  @media (max-width: 768px) {
    font-size: 14px; /* Diminue la taille de la police sur mobile */
    padding: 12px 15px; /* Réduit le padding sur mobile */
  }
`;

const SearchButton = styled.button`
  background: none;
  border: none;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #005357;
`;

const MemberList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const MemberCard = styled.div`
  display: flex;
  align-items: center;
  background-color: #f2f6f6;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 80%;
  box-sizing: border-box;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 95%; /* Augmente la largeur des cartes sur mobile */
    padding: 15px; /* Réduit le padding pour mieux s'adapter */
  }
`;

const ProfileImage = styled.img`
  border-radius: 50%;
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-right: 20px;

  @media (max-width: 768px) {
    width: 60px; /* Réduit la taille de l'image sur mobile */
    height: 60px;
  }
`;

const MemberDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const MemberName = styled.h2`
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-size: 18px;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 16px; /* Réduit la taille de la police sur mobile */
  }
`;

const MemberInfo = styled.p`
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-size: 14px;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 12px; /* Réduit la taille des infos sur mobile */
  }
`;

function Membres() {
  const [members, setMembers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetch('https://app.konex-business.fr/api/users')
      .then(response => response.json())
      .then(data => {
        setMembers(data);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des membres:', error);
      });
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCardClick = (username) => {
    navigate(`/profile/${username}`);
  };

  const filteredMembers = members.filter(member =>
    member.nom.toLowerCase().includes(searchTerm.toLowerCase()) ||
    member.prenom.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Container>
      <Title>Membres</Title>
      <SearchBarContainer>
        <Input
          type="text"
          placeholder="Rechercher un membre..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <SearchButton>
          <FaSearch size={20} />
        </SearchButton>
      </SearchBarContainer>
      <MemberList>
        {filteredMembers.map(member => (
          <MemberCard key={member.id} onClick={() => handleCardClick(member.username)}>
            <ProfileImage src={`https://konex-business.fr/${member.profile_picture}`} alt={`${member.nom} ${member.prenom}`} />
            <MemberDetails>
              <MemberName>{member.prenom} {member.nom}</MemberName>
              <MemberInfo>Société: {member.societe || 'N/A'}</MemberInfo>
              <MemberInfo>Activité: {member.activite || 'N/A'}</MemberInfo>
            </MemberDetails>
          </MemberCard>
        ))}
      </MemberList>
    </Container>
  );
}

export default Membres;

