import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: #f8f8f8;
  height: 100vh;
  overflow: auto;
  box-sizing: border-box;
`;

const Title = styled.h1`
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 700;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  padding: 0 20px;
  box-sizing: border-box;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
  margin-bottom: 20px;

  th, td {
    border: 1px solid #d9e1e8;
    padding: 10px;
    text-align: center;
    font-family: 'Avenir Next', sans-serif;
    color: #005357;
    background-color: #f2f6f6;
  }

  th {
    font-weight: 700;
    color: #ffffff;
    background-color: #005357;
  }

  @media (max-width: 768px) {
    table, thead, tbody, th, td, tr {
      display: block;
    }

    th {
      display: none; /* Cache les en-têtes dans la version mobile */
    }

    td {
      display: flex;
      justify-content: space-between; /* Sépare le label et les chiffres */
      align-items: center;
      padding: 10px;
      border: 1px solid #d9e1e8;
      padding-left: 30px; /* Réserve plus de place pour le label */
      text-align: right; /* Aligne les chiffres à droite */
    }

    td::before {
      content: attr(data-label);
      font-weight: bold;
      color: #005357;
      text-align: left;
      padding-right: 15px; /* Ajoute un espace entre le label et le chiffre */
    }

    td[data-label="Connexions Données"],
    td[data-label="Connexions Reçues"] {
      padding-right: 20px; /* Ajoute plus d'espace pour les chiffres */
      text-align: right;
    }

    tr {
      margin-bottom: 15px;
      border: 1px solid #d9e1e8;
      border-radius: 10px;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  margin-top: 20px;

  button {
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    align-items: center;
    button {
      width: 100%;
      margin-bottom: 15px;
    }
  }
`;

const Button = styled.button`
  padding: 15px;
  background-color: #00A2A2;
  color: #fff;
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 200px;

  @media (max-width: 480px) {
    width: 100%;
  }
`;

function StatistiquesTousMembres() {
  const [stats, setStats] = useState([]);

  useEffect(() => {
    fetchStats();
  }, []);

  const fetchStats = async () => {
    try {
      const response = await fetch('/api/all-users-stats'); // Remplacez par l'endpoint de votre API pour tous les utilisateurs
      const data = await response.json();
      setStats(data);
    } catch (error) {
      console.error('Erreur lors de la récupération des statistiques:', error);
    }
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
    const tableData = stats.map(stat => ([
      stat.nom,
      stat.prenom,
      stat.connexions_donnees,
      stat.connexions_recues,
      stat.entretiens_donnees,
      stat.invites,
      stat.mercis_donnees,
      stat.mercis_recues
    ]));
    doc.autoTable({
      head: [['Nom', 'Prénom', 'Connexions Données', 'Connexions Reçues', 'Entretiens', 'Invités', 'Merci Donnés', 'Merci Reçus']],
      body: tableData
    });
    doc.save('rapport_statistiques_tous_membres.pdf');
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(stats.map(stat => ({
      Nom: stat.nom,
      Prénom: stat.prenom,
      'Connexions Données': stat.connexions_donnees,
      'Connexions Reçues': stat.connexions_recues,
      Entretiens: stat.entretiens_donnees,
      Invités: stat.invites,
      'Merci Donnés': stat.mercis_donnees,
      'Merci Reçus': stat.mercis_recues
    })));
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Statistiques');
    XLSX.writeFile(workbook, 'rapport_statistiques_tous_membres.xlsx');
  };

  return (
    <Container>
      <Title>Rapport Statistiques Tous les Membres</Title>

      <TableContainer>
        <Table>
          <thead>
            <tr>
              <th>Nom</th>
              <th>Prénom</th>
              <th>Connexions Données</th>
              <th>Connexions Reçues</th>
              <th>Entretiens</th>
              <th>Invités</th>
              <th>Merci Donnés</th>
              <th>Merci Reçus</th>
            </tr>
          </thead>
          <tbody>
            {stats.map((stat, index) => (
              <tr key={index}>
                <td data-label="Nom">{stat.nom}</td>
                <td data-label="Prénom">{stat.prenom}</td>
                <td data-label="Connexions Données">{stat.connexions_donnees || 0}</td>
                <td data-label="Connexions Reçues">{stat.connexions_recues || 0}</td>
                <td data-label="Entretiens">{stat.entretiens_donnees || 0}</td>
                <td data-label="Invités">{stat.invites || 0}</td>
                <td data-label="Merci Donnés">{stat.mercis_donnees || 0}</td>
                <td data-label="Merci Reçus">{stat.mercis_recues || 0}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>

      <ButtonContainer>
        <Button type="button" onClick={downloadPDF}>Télécharger Rapport PDF</Button>
        <Button type="button" onClick={downloadExcel}>Télécharger Rapport Excel</Button>
      </ButtonContainer>
    </Container>
  );
}

export default StatistiquesTousMembres;

