import React, { useReducer, useContext, useMemo, useEffect, useState } from 'react';
import { Link, useNavigate, useLocation, NavLink } from 'react-router-dom';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import {
  FaHome, FaUsers, FaUser, FaEuroSign, FaChartBar, FaBell, FaTh, FaSignOutAlt,
  FaFacebook, FaLinkedin, FaArrowLeft, FaHandshake, FaSignInAlt, FaFileInvoice,
  FaUserTie, FaClipboardList, FaExchangeAlt, FaReceipt, FaHandsHelping, FaEnvelopeOpenText,
  FaAddressCard, FaGift
} from 'react-icons/fa';
import UserContext from './UserContext';
import AdminLoginModal from './AdminLoginModal';

// Global Styles
const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    overflow-x: hidden;
    padding-top: 60px;
  }

  a {
    color: white;
    text-decoration: none;
  }

  button {
    background: none;
    border: none;
    color: #00A2A2;
    cursor: pointer;
  }

  button:focus {
    outline: 2px solid #00A2A2;
  }
`;

// Animation keyframes
const slideIn = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

// Styles for components
const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  height: 60px;
`;

const Logo = styled.img`
  position: absolute;
  left: 20px;
  height: 40px;
  width: auto;
  cursor: pointer;

  @media (max-width: 768px) {
    height: 30px;
  }
`;

const NavWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 600px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 768px) {
    display: none;
  }
`;

const NavIcons = styled.nav`
  display: flex;
  align-items: center;
  gap: 30px;
  color: #00A2A2;
`;

const IconLink = styled(NavLink)`
  color: ${({ active }) => (active ? '#005f5f' : 'inherit')};
  font-size: 20px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: background-color 0.3s ease, transform 0.1s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }

  &:active {
    transform: scale(0.9);
  }
`;

const ProfileSection = styled.div`
  position: absolute;
  right: 20px;
  display: flex;
  align-items: center;
  gap: 15px;
`;

const ProfileImage = styled.img`
  height: 45px;
  width: 45px;
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
  transition: filter 0.3s ease;

  &:hover {
    filter: brightness(0.8);
  }

  @media (max-width: 768px) {
    height: 40px;
    width: 40px;
  }
`;

const ProfileMenu = styled.div`
  position: absolute;
  top: 60px;
  left: 0;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
  z-index: 1001;
  display: ${({ open }) => (open ? 'block' : 'none')};

  a, button {
    display: block;
    padding: 10px 20px;
    text-align: left;
    width: 100%;
    color: #00A2A2;
    transition: background-color 0.3s ease;
  }

  a:hover, button:hover {
    background-color: #f3fbff;
  }
`;

const MenuButton = styled.button`
  color: #00A2A2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  transition: background-color 0.3s ease, transform 0.1s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }

  &:active {
    transform: scale(0.9);
  }

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;

const StyledFaTh = styled(FaTh)`
  font-size: 24px;
  color: #00A2A2;
`;

const ContentContainer = styled.main`
  padding: 20px;
`;

const MenuContainer = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00A2A2;
  color: white;
  padding: 80px 5% 20px 5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  transition: transform 0.3s ease;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
  z-index: 999;
  border-radius: 9px;
  overflow-y: auto;
  max-height: 100vh;
  animation: ${({ open }) => (open ? slideIn : slideOut)} 0.3s ease;

  @media (min-width: 769px) {
    width: 300px;
    left: 0;
    padding-left: 20px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SectionTitle = styled.h3`
  color: white;
  font-size: 1.5rem;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  width: 100%;
  text-align: left;
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  min-width: 100%;
  padding: 12px 15px;
  border-radius: 9px;
  text-decoration: none;
  color: white;
  white-space: nowrap;
  line-height: 1.5;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }
`;

const MenuIcon = styled.span`
  margin-right: 10px;
  font-size: 20px;
`;

const MenuText = styled.span`
  font-size: 1.2rem;
  color: inherit;
  flex-grow: 1;
`;

const SubMenu = styled.div`
  margin-top: 30px;
`;

const BackLink = styled.button`
  padding: 10px;
  color: white;
  display: flex;
  align-items: center;
  gap: 10px;
  background: none;
  cursor: pointer;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

// Styles pour les icônes des réseaux sociaux
const SocialMediaContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 15px;
`;

const SocialMediaTitle = styled.h4`
  color: white;
  margin-bottom: 10px;
  text-align: center;
`;

const SocialIconLink = styled.a`
  color: white;
  font-size: 24px;
  transition: color 0.3s ease;

  &:hover {
    color: #00A2A2; // Change la couleur au survol
  }
`;

// Initial State
const initialState = {
  menuOpen: false,
  inActivitySubMenu: false,
  profileMenuOpen: false,
};

// Reducer function
const reducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_MENU':
      return { ...state, menuOpen: !state.menuOpen };
    case 'OPEN_ACTIVITY_SUBMENU':
      return { ...state, inActivitySubMenu: true };
    case 'CLOSE_ACTIVITY_SUBMENU':
      return { ...state, inActivitySubMenu: false };
    case 'CLOSE_MENU':
      return { ...state, menuOpen: false, inActivitySubMenu: false };
    case 'TOGGLE_PROFILE_MENU':
      return { ...state, profileMenuOpen: !state.profileMenuOpen };
    case 'CLOSE_PROFILE_MENU':
      return { ...state, profileMenuOpen: false };
    default:
      return state;
  }
};

const MenuComponent = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const location = useLocation();
  const { user, logout } = useContext(UserContext);

  const [adminModalOpen, setAdminModalOpen] = useState(false);
  const [adminUsername, setAdminUsername] = useState('');
  const [adminPassword, setAdminPassword] = useState('');
  const [adminError, setAdminError] = useState('');

  const handleAdminLogin = async () => {
    try {
      const response = await fetch('/api/admin/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: adminUsername, password: adminPassword }),
      });

      const data = await response.json();
      if (response.ok) {
        setAdminModalOpen(false);
        navigate('/adminstatgroup');
      } else {
        setAdminError(data.message);
      }
    } catch (error) {
      setAdminError('Erreur de connexion.');
    }
  };

  const handleProfileClick = () => {
    if (user?.username) {
      navigate(`/profile/${user.username}`);
    } else {
      console.error("Identifiant utilisateur introuvable.");
    }
  };

  const profileImageUrl = useMemo(() => {
    return user?.profile_picture
      ? `https://konex-business.fr/${user.profile_picture}`
      : "https://via.placeholder.com/45";
  }, [user]);

  const toggleMenu = () => dispatch({ type: 'TOGGLE_MENU' });
  const openActivitySubMenu = () => dispatch({ type: 'OPEN_ACTIVITY_SUBMENU' });
  const closeActivitySubMenu = () => dispatch({ type: 'CLOSE_ACTIVITY_SUBMENU' });
  const toggleProfileMenu = () => dispatch({ type: 'TOGGLE_PROFILE_MENU' });
  const closeProfileMenu = () => dispatch({ type: 'CLOSE_PROFILE_MENU' });

  const handleLogout = () => {
    closeProfileMenu();
    logout();
    navigate('/seconnecter');
  };

  useEffect(() => {
    dispatch({ type: 'CLOSE_MENU' });
  }, [location.pathname]);

  return (
    <>
      <GlobalStyle />
      <Header>
        <Logo
          src="https://app.konex-business.fr/favicon2.png"
          alt="Logo Konex Business"
          onClick={() => {
            navigate('/');
            dispatch({ type: 'CLOSE_MENU' });
          }}
        />
        <NavWrapper>
          <NavIcons aria-label="Navigation principale">
            <IconLink to="/" exact activeClassName="active" aria-label="Accueil">
              <FaHome />
            </IconLink>
            <IconLink to="/membres" activeClassName="active" aria-label="Liste des Membres">
              <FaUsers />
            </IconLink>
            <IconLink
              to={`/profile/${user?.username}`}
              activeClassName="active"
              aria-label="Profil"
            >
              <FaUser />
            </IconLink>
            <IconLink to="/recapmercirecu" activeClassName="active" aria-label="Recap Merci Reçu">
              <FaEuroSign />
            </IconLink>
            <IconLink to="/statistiquesmembre" activeClassName="active" aria-label="Statistiques">
              <FaChartBar />
            </IconLink>
          </NavIcons>
        </NavWrapper>
        <ProfileSection>
          <MenuButton onClick={toggleMenu} aria-label="Menu">
            <StyledFaTh />
          </MenuButton>
          <ProfileImage
            src={profileImageUrl}
            alt={`Photo de profil de ${user?.prenom} ${user?.nom}`}
            onClick={toggleProfileMenu}
          />
          {state.profileMenuOpen && (
            <ProfileMenu open={state.profileMenuOpen}>
              <a href="https://konex-business.fr">Aller vers le site</a>
              <button onClick={handleLogout}>Déconnexion</button>
            </ProfileMenu>
          )}
        </ProfileSection>
      </Header>

      <ContentContainer>
        {/* Contenu principal de la page */}
      </ContentContainer>

      {state.menuOpen && (
        <MenuContainer open={state.menuOpen}>
          {!state.inActivitySubMenu ? (
            <>
              <SectionTitle>Menu Principal</SectionTitle>
              <MenuItem>
                <MenuIcon><FaHome /></MenuIcon>
                <Link to="/" onClick={() => dispatch({ type: 'CLOSE_MENU' })}>
                  <MenuText>Accueil</MenuText>
                </Link>
              </MenuItem>
              <MenuItem>
                <MenuIcon><FaUsers /></MenuIcon>
                <Link to="/membres" onClick={() => dispatch({ type: 'CLOSE_MENU' })}>
                  <MenuText>Liste des Membres</MenuText>
                </Link>
              </MenuItem>
              <MenuItem>
                <MenuIcon><FaUser /></MenuIcon>
                <Link to={`/profile/${user?.username}`} onClick={() => dispatch({ type: 'CLOSE_MENU' })}>
                  <MenuText>Profil</MenuText>
                </Link>
              </MenuItem>
              <MenuItem>
                <MenuIcon><FaEuroSign /></MenuIcon>
                <Link to="/recapmercirecu" onClick={() => dispatch({ type: 'CLOSE_MENU' })}>
                  <MenuText>Recap Merci Reçu</MenuText>
                </Link>
              </MenuItem>
              <MenuItem>
                <MenuIcon><FaChartBar /></MenuIcon>
                <Link to="/statistiquesmembre" onClick={() => dispatch({ type: 'CLOSE_MENU' })}>
                  <MenuText>Statistiques</MenuText>
                </Link>
              </MenuItem>
              <MenuItem>
                <MenuIcon><FaChartBar /></MenuIcon>
                <Link as="button" onClick={() => setAdminModalOpen(true)}>
                  <MenuText>Stat Group (Admin)</MenuText>
                </Link>
              </MenuItem>
              <SectionTitle onClick={openActivitySubMenu} style={{ cursor: 'pointer' }}>
                Mon activité
              </SectionTitle>
            </>
          ) : (
            <>
              <SectionTitle>Mon activité</SectionTitle>
              <SubMenu>
                <MenuItem>
                  <MenuIcon><FaHandshake /></MenuIcon>
                  <Link to="/entrerunmerci" onClick={() => dispatch({ type: 'CLOSE_MENU' })}>
                    <MenuText>Entrer un Merci</MenuText>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <MenuIcon><FaSignInAlt /></MenuIcon>
                  <Link to="/entreruneconnexion" onClick={() => dispatch({ type: 'CLOSE_MENU' })}>
                    <MenuText>Entrer une Connexion</MenuText>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <MenuIcon><FaFileInvoice /></MenuIcon>
                  <Link to="/entreruneentrevue" onClick={() => dispatch({ type: 'CLOSE_MENU' })}>
                    <MenuText>Entrer une Entrevue</MenuText>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <MenuIcon><FaUserTie /></MenuIcon>
                  <Link to="/entreruninvite" onClick={() => dispatch({ type: 'CLOSE_MENU' })}>
                    <MenuText>Entrer un Invité</MenuText>
                  </Link>
                </MenuItem>

                {/* Added Links */}
                <MenuItem>
                  <MenuIcon><FaClipboardList /></MenuIcon>
                  <Link to="/recapkonexrecu" onClick={() => dispatch({ type: 'CLOSE_MENU' })}>
                    <MenuText>Recap Konex Recu</MenuText>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <MenuIcon><FaExchangeAlt /></MenuIcon>
                  <Link to="/recapkonexdonne" onClick={() => dispatch({ type: 'CLOSE_MENU' })}>
                    <MenuText>Recap Konex Donné</MenuText>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <MenuIcon><FaReceipt /></MenuIcon>
                  <Link to="/recapmercirecu" onClick={() => dispatch({ type: 'CLOSE_MENU' })}>
                    <MenuText>Recap Merci Reçu</MenuText>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <MenuIcon><FaHandsHelping /></MenuIcon>
                  <Link to="/recapmercidonne" onClick={() => dispatch({ type: 'CLOSE_MENU' })}>
                    <MenuText>Recap Mercis Donnés</MenuText>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <MenuIcon><FaEnvelopeOpenText /></MenuIcon>
                  <Link to="/recapentrevuerecu" onClick={() => dispatch({ type: 'CLOSE_MENU' })}>
                    <MenuText>Recap Entrevue Reçu</MenuText>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <MenuIcon><FaAddressCard /></MenuIcon>
                  <Link to="/recapentrevuedonnee" onClick={() => dispatch({ type: 'CLOSE_MENU' })}>
                    <MenuText>Recap Entrevue Donnée</MenuText>
                  </Link>
                </MenuItem>

                {/* Bons section */}
                <MenuItem>
                  <MenuIcon><FaGift /></MenuIcon>
                  <Link to="/bonsrecus" onClick={() => dispatch({ type: 'CLOSE_MENU' })}>
                    <MenuText>Bons Reçus</MenuText>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <MenuIcon><FaGift /></MenuIcon>
                  <Link to="/bonsdonnes" onClick={() => dispatch({ type: 'CLOSE_MENU' })}>
                    <MenuText>Bons Donnés</MenuText>
                  </Link>
                </MenuItem>

                {/* Reintegrating 'Liste des Invités' */}
                <MenuItem>
                  <MenuIcon><FaUserTie /></MenuIcon>
                  <Link to="/listedesinvites" onClick={() => dispatch({ type: 'CLOSE_MENU' })}>
                    <MenuText>Liste des Invités</MenuText>
                  </Link>
                </MenuItem>
              </SubMenu>

              <BackLink onClick={closeActivitySubMenu}>
                <FaArrowLeft />
                <span>Retour au menu principal</span>
              </BackLink>
            </>
          )}


          <SocialMediaContainer>
            <SocialMediaTitle>Nos Réseaux Sociaux</SocialMediaTitle>
            <SocialIconLink href="https://www.facebook.com/profile.php?id=61558708762500" target="_blank" aria-label="Facebook">
              <FaFacebook />
            </SocialIconLink>
            <SocialIconLink href="https://www.linkedin.com/company/konex-business/" target="_blank" aria-label="LinkedIn">
              <FaLinkedin />
            </SocialIconLink>
          </SocialMediaContainer>

          <MenuItem onClick={handleLogout} style={{ marginTop: 'auto' }}>
            <MenuIcon><FaSignOutAlt /></MenuIcon>
            <MenuText>Déconnexion</MenuText>
          </MenuItem>
        </MenuContainer>
      )}

      {adminModalOpen && (
        <AdminLoginModal
          onClose={() => setAdminModalOpen(false)}
          onLogin={handleAdminLogin}
          adminUsername={adminUsername}
          setAdminUsername={setAdminUsername}
          adminPassword={adminPassword}
          setAdminPassword={setAdminPassword}
          adminError={adminError}
        />
      )}
    </>
  );
};

export default MenuComponent;
