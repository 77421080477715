import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Assurez-vous que vous avez installé axios si vous l'utilisez pour les requêtes

// Styles pour le modal
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 10px;
  font-weight: bold;
  color: #005357;
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #d9e1e8;
  border-radius: 5px;
  font-size: 16px;
  color: #005357;
`;

const Button = styled.button`
  padding: 10px;
  background-color: #00A2A2;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #007f7f;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  margin-top: 10px;
`;

const AdminLoginModal = ({ onClose }) => {
  const navigate = useNavigate();
  const [adminUsername, setAdminUsername] = useState('');
  const [adminPassword, setAdminPassword] = useState('');
  const [adminError, setAdminError] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      // Envoyer une requête de connexion
      const response = await axios.post('https://app.konex-business.fr/api/admin/login', {
        username: adminUsername,
        password: adminPassword,
      });

      if (response.status === 200) {
        // Connexion réussie, fermer le modal
        onClose();
        // Rediriger vers la page admin
        navigate('/adminstatgroup');
      } else {
        // Gérer une réponse qui n'est pas 200 OK
        setAdminError('Nom d’utilisateur ou mot de passe incorrect');
      }
    } catch (error) {
      // Gérer les erreurs
      if (error.response && error.response.status === 401) {
        setAdminError('Nom d’utilisateur ou mot de passe incorrect');
      } else {
        setAdminError('Erreur du serveur, veuillez réessayer.');
      }
    }
  };

  return (
    <ModalOverlay>
      <ModalContent>
        <h2>Connexion Admin</h2>
        <Form onSubmit={handleLogin}>
          <Label htmlFor="username">Nom d'utilisateur</Label>
          <Input
            id="username"
            type="text"
            value={adminUsername}
            onChange={(e) => setAdminUsername(e.target.value)}
            required
          />

          <Label htmlFor="password">Mot de passe</Label>
          <Input
            id="password"
            type="password"
            value={adminPassword}
            onChange={(e) => setAdminPassword(e.target.value)}
            required
          />

          {adminError && <ErrorMessage>{adminError}</ErrorMessage>}

          <Button type="submit">Se connecter</Button>
          <Button type="button" onClick={onClose} style={{ marginTop: '10px', backgroundColor: '#b6cc00' }}>
            Annuler
          </Button>
        </Form>
      </ModalContent>
    </ModalOverlay>
  );
};

export default AdminLoginModal;

