import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: #f8f8f8;
  height: 100vh;
  overflow: auto;
  box-sizing: border-box;
`;

const Title = styled.h1`
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 700;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const Button = styled(Link)`
  display: block;
  width: 300px;
  padding: 15px;
  margin-bottom: 20px;
  text-align: center;
  background-color: #00A2A2;
  color: #fff;
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  border: none;
  text-decoration: none;

  &:hover {
    background-color: #007a7a;
  }

  @media (max-width: 768px) {
    width: 100%;
    font-size: 14px;
    padding: 15px;
  }
`;

function AdminStatGroup() {
  return (
    <Container>
      <Title>ADMINISTRATION STATS GROUPE</Title>
      <Button to="/statistiquestousmembres">ACCÈS STATISTIQUES GROUPE</Button> {/* Mise à jour de la route ici */}
      <Button to="/statistiquesmembre">ACCÈS STATISTIQUES MEMBRES</Button>
      <Button to="/listedesinvites">ACCÈS LISTE INVITÉS</Button>
    </Container>
  );
}

export default AdminStatGroup;

