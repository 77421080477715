import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import UserContext from './UserContext';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: #f8f8f8;
  height: 100vh;
  overflow: auto;
  box-sizing: border-box;
`;

const Title = styled.h1`
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 700;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  padding: 0 20px;
  box-sizing: border-box;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
  margin-bottom: 20px;

  th, td {
    border: 1px solid #d9e1e8;
    padding: 10px;
    text-align: center;
    font-family: 'Avenir Next', sans-serif;
    color: #005357;
    background-color: #f2f6f6;
  }

  th {
    font-weight: 700;
    color: #ffffff;
    background-color: #005357;
  }

  @media (max-width: 768px) {
    table, thead, tbody, th, td, tr {
      display: block;
    }

    th {
      display: none; /* Cache les en-têtes dans la version mobile */
    }

    td {
      display: flex;
      justify-content: space-between; /* Sépare le label et les chiffres */
      align-items: center;
      padding: 10px;
      border: 1px solid #d9e1e8;
      padding-left: 30px; /* Réserve plus de place pour le label */
      text-align: right; /* Aligne les chiffres à droite */
    }

    td::before {
      content: attr(data-label);
      font-weight: bold;
      color: #005357;
      text-align: left;
      padding-right: 15px; /* Ajoute un espace entre le label et le chiffre */
    }

    td[data-label="Connexions Données"],
    td[data-label="Connexions Reçues"] {
      padding-right: 20px; /* Ajoute plus d'espace pour les chiffres */
      text-align: right;
    }

    tr {
      margin-bottom: 15px;
      border: 1px solid #d9e1e8;
      border-radius: 10px;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  margin-top: 20px;

  button {
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    align-items: center;
    button {
      width: 100%;
      margin-bottom: 15px;
    }
  }
`;

const Button = styled.button`
  padding: 15px;
  background-color: #00A2A2;
  color: #fff;
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 200px;

  @media (max-width: 480px) {
    width: 100%;
  }
`;

function StatistiquesMembre() {
  const { user } = useContext(UserContext);
  const [dateDebut, setDateDebut] = useState('');
  const [dateFin, setDateFin] = useState('');
  const [stats, setStats] = useState({});

  useEffect(() => {
    const today = new Date();
    const lastMonth = new Date();
    lastMonth.setMonth(today.getMonth() - 1);

    setDateDebut(lastMonth.toISOString().substr(0, 10));
    setDateFin(today.toISOString().substr(0, 10));

    fetchStats(user.id, lastMonth, today);
  }, [user]);

  const fetchStats = async (userId, startDate, endDate) => {
    try {
      const response = await fetch(`/api/personal-stats?user_id=${userId}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`);
      const data = await response.json();
      setStats(data);
    } catch (error) {
      console.error('Erreur lors de la récupération des statistiques:', error);
    }
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [['Connexions Données', 'Connexions Reçues', 'Entretiens', 'Invités', 'Merci Donnés', 'Merci Reçus']],
      body: [[
        stats.connexions_donnees,
        stats.connexions_recues,
        stats.entretiens_donnees,
        stats.invites,
        stats.mercis_donnees,
        stats.mercis_recues
      ]]
    });
    doc.save('rapport_statistiques.pdf');
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet([{
      'Connexions Données': stats.connexions_donnees,
      'Connexions Reçues': stats.connexions_recues,
      Entretiens: stats.entretiens_donnees,
      Invités: stats.invites,
      'Merci Donnés': stats.mercis_donnees,
      'Merci Reçus': stats.mercis_recues
    }]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Statistiques');
    XLSX.writeFile(workbook, 'rapport_statistiques.xlsx');
  };

  return (
    <Container>
      <Title>Rapport statistiques Membre</Title>

      <TableContainer>
        <Table>
          <thead>
            <tr>
              <th>Connexions Données</th>
              <th>Connexions Reçues</th>
              <th>Entretiens</th>
              <th>Invités</th>
              <th>Merci Donnés</th>
              <th>Merci Reçus</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td data-label="Connexions Données">{stats.connexions_donnees || 0}</td>
              <td data-label="Connexions Reçues">{stats.connexions_recues || 0}</td>
              <td data-label="Entretiens">{stats.entretiens_donnees || 0}</td>
              <td data-label="Invités">{stats.invites || 0}</td>
              <td data-label="Merci Donnés">{stats.mercis_donnees || 0}</td>
              <td data-label="Merci Reçus">{stats.mercis_recues || 0}</td>
            </tr>
          </tbody>
        </Table>
      </TableContainer>

      <ButtonContainer>
        <Button type="button" onClick={downloadPDF}>Télécharger Rapport PDF</Button>
        <Button type="button" onClick={downloadExcel}>Télécharger Rapport Excel</Button>
      </ButtonContainer>
    </Container>
  );
}

export default StatistiquesMembre;

