import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { fr } from 'date-fns/locale';
import UserContext from './UserContext';

registerLocale('fr', fr);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: #f8f8f8;
  min-height: 100vh;
  overflow: auto;
  box-sizing: border-box;
`;

const Title = styled.h1`
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 700;
  margin-bottom: 40px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
`;

const FormControl = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 10px;
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 500;
`;

const SmallInput = styled.input`
  padding: 10px;
  border: 1px solid #d9e1e8;
  font-family: 'Avenir Next', sans-serif;
  font-size: 14px;
  color: #005357;
  background-color: #f2f6f6;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
`;

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  padding: 10px;
  border: 1px solid #d9e1e8;
  font-family: 'Avenir Next', sans-serif;
  font-size: 14px;
  color: #005357;
  background-color: #f2f6f6;
  border-radius: 5px;
  box-sizing: border-box;
`;

const Button = styled.button`
  padding: 15px;
  background-color: #005357;
  color: #fff;
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

const ConnectionList = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
`;

const ConnectionItem = styled.div`
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
`;

const ConnectionDetail = styled.p`
  padding: 5px 0;
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-size: 14px;
  margin: 0;
`;

const BoldText = styled(ConnectionDetail)`
  font-weight: bold;
`;

const SectionTitle = styled.h2`
  margin: 20px 0 10px 0;
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 600;
  font-size: 18px;
  text-align: left;
`;

const ErrorMessage = styled.p`
  color: red;
  margin-top: 20px;
`;

const InfoMessage = styled.p`
  color: #005357;
  margin-top: 20px;
  font-family: 'Avenir Next', sans-serif;
  font-size: 14px;
  text-align: center;
`;

const UserProfileImage = styled.img`
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 10px;
`;

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const GreyLine = styled.hr`
  border: none;
  border-top: 1px solid #d9e1e8;
  margin: 20px 0;
`;

const ItalicText = styled.p`
  font-style: italic;
  color: #005357;
  margin-bottom: 10px;
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RecapKonexDonne = () => {
  const { user } = useContext(UserContext);
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [connections, setConnections] = useState([]);
  const [error, setError] = useState('');
  const [infoMessage, setInfoMessage] = useState('Nous affichons par défaut les 5 dernières connexions.');

  useEffect(() => {
    if (user) {
      setNom(user.nom);
      setPrenom(user.prenom);
      fetchConnections(user.id);
    }
  }, [user]);

  const fetchConnections = async (userId, startDate = null, endDate = null) => {
    let url = `https://app.konex-business.fr/api/connections_given?user_id=${userId}`;
    if (startDate && endDate) {
      url += `&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
    }
    try {
      const response = await fetch(url);
      const responseText = await response.text();
      if (response.headers.get('content-type')?.includes('application/json')) {
        const data = JSON.parse(responseText);
        setConnections(data);
        if (startDate && endDate) {
          setInfoMessage(`Affichage des connexions données du ${startDate.toLocaleDateString('fr-FR')} au ${endDate.toLocaleDateString('fr-FR')}`);
        } else {
          setInfoMessage('Nous affichons par défaut les 5 dernières connexions.');
        }
        if (data.length === 0) {
          setInfoMessage('Aucune connexion donnée pour cette période.');
        }
      } else {
        throw new Error('La réponse n\'est pas en JSON');
      }
    } catch (error) {
      setError('Erreur lors de la récupération des connexions données');
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    fetchConnections(user.id, startDate, endDate);
  };

  return (
    <Container>
      <Title>Connexions Données</Title>
      <Form onSubmit={handleSearch}>
        <Row>
          <FormControl>
            <Label htmlFor="nom">Nom</Label>
            <SmallInput
              id="nom"
              type="text"
              value={nom}
              readOnly
            />
          </FormControl>
          <FormControl>
            <Label htmlFor="prenom">Prénom</Label>
            <SmallInput
              id="prenom"
              type="text"
              value={prenom}
              readOnly
            />
          </FormControl>
        </Row>
        <FormControl>
          <Label htmlFor="periode">Période</Label>
          <StyledDatePicker
            id="periode"
            selected={startDate}
            onChange={(update) => {
              setStartDate(update[0]);
              setEndDate(update[1]);
            }}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            locale="fr"
            dateFormat="dd/MM/yyyy"
            placeholderText="Sélectionner une période"
          />
        </FormControl>
        <Button type="submit">Rechercher</Button>
      </Form>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {infoMessage && <InfoMessage>{infoMessage}</InfoMessage>}
      <ConnectionList>
        {connections.map((connection) => (
          <ConnectionItem key={connection.id}>
            <ItalicText>Connexion effectuée le {new Date(connection.date).toLocaleDateString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' })}</ItalicText>
            <ProfileContainer>
              <UserProfileImage src={`https://konex-business.fr/${connection.profile_picture}`} alt="Profile" />
              <div>
                <BoldText>{connection.prenom} {connection.nom}</BoldText>
                <ConnectionDetail>{connection.adresse}, {connection.cp}, {connection.ville}</ConnectionDetail>
                <ConnectionDetail>{connection.telephone}</ConnectionDetail>
              </div>
            </ProfileContainer>
            <GreyLine />
            <SectionTitle>INFORMATIONS CONNEXION</SectionTitle>
            <ConnectionDetail>{connection.nom} {connection.prenom}</ConnectionDetail>
            <ConnectionDetail>Société : {connection.societe}</ConnectionDetail>
            <ConnectionDetail>Email : {connection.email}</ConnectionDetail>
            <ConnectionDetail>Description : {connection.description}</ConnectionDetail>
          </ConnectionItem>
        ))}
      </ConnectionList>
    </Container>
  );
};

export default RecapKonexDonne;

