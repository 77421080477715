import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import UserContext from './UserContext';

// Définition des composants stylisés
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: #f8f8f8;
  min-height: 100vh;
  overflow: auto;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 0; /* Suppression du padding sur smartphone */
  }
`;

const Title = styled.h1`
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 700;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    font-size: 28px; /* Augmentation de la taille du titre sur smartphone */
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 0; /* Suppression du padding sur smartphone */
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
  }
`;

const FormControl = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 10px;
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 18px; /* Augmentation de la taille de la police sur smartphone */
  }
`;

const SmallInput = styled.input`
  padding: 15px;
  border: 1px solid #d9e1e8;
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  color: #005357;
  background-color: #f2f6f6;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    font-size: 18px;
    padding: 18px;
    height: 55px;
    width: 100%; /* Largeur maximale sur smartphone */
  }
`;

const TextArea = styled.textarea`
  padding: 10px;
  border: 1px solid #d9e1e8;
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  color: #005357;
  background-color: #f2f6f6;
  border-radius: 5px;
  height: 120px;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    font-size: 18px;
    padding: 18px;
    height: 100px;
  }
`;

const Select = styled.select`
  padding: 10px;
  border: 1px solid #d9e1e8;
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  color: #005357;
  background-color: #f2f6f6;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    font-size: 18px;
    padding: 18px;
  }
`;

const Button = styled.button`
  padding: 15px;
  background-color: #005357;
  color: #fff;
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 18px;
    font-size: 18px; /* Augmentation de la taille des boutons sur smartphone */
  }
`;

const ErrorMessage = styled.p`
  color: red;
  margin-top: 20px;
`;

const SuccessMessage = styled.p`
  color: green;
  margin-top: 20px;
`;

// Composant EntrerUneEntrevue
function EntrerUneEntrevue() {
  const { user } = useContext(UserContext);
  const [users, setUsers] = useState([]);
  const [avec, setAvec] = useState('');
  const [date, setDate] = useState('');
  const [lieu, setLieu] = useState('');
  const [description, setDescription] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    fetch('https://app.konex-business.fr/api/users')
      .then((response) => response.json())
      .then((data) => setUsers(data))
      .catch((error) => console.error('Erreur lors de la récupération des utilisateurs:', error));
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setError('');
    setSuccess('');

    const entrevueData = {
      userId: user.id,
      avec,
      date,
      lieu,
      description,
    };

    fetch('https://app.konex-business.fr/api/entrevue', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(entrevueData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setSuccess('Entrevue enregistrée avec succès');
        } else {
          setError('Erreur lors de l\'enregistrement de l\'entrevue');
        }
      })
      .catch(() => {
        setError('Erreur lors de l\'enregistrement de l\'entrevue');
      });
  };

  return (
    <Container>
      <Title>Entrer une entrevue</Title>
      <Form onSubmit={handleSubmit}>
        <FormControl>
          <Label htmlFor="avec">Avec :</Label>
          <Select id="avec" value={avec} onChange={(e) => setAvec(e.target.value)}>
            <option value="">Choisir un utilisateur</option>
            {users.map((user) => (
              <option key={user.id} value={user.id}>
                {user.nom} {user.prenom}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <Label htmlFor="date">Date</Label>
          <SmallInput
            id="date"
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            placeholder="Date"
          />
        </FormControl>
        <FormControl>
          <Label htmlFor="lieu">Lieu</Label>
          <SmallInput
            id="lieu"
            type="text"
            value={lieu}
            onChange={(e) => setLieu(e.target.value)}
            placeholder="Lieu"
          />
        </FormControl>
        <FormControl>
          <Label htmlFor="description">Description</Label>
          <TextArea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Description"
          />
        </FormControl>
        <Button type="submit">Entrer</Button>
      </Form>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {success && <SuccessMessage>{success}</SuccessMessage>}
    </Container>
  );
}

export default EntrerUneEntrevue;

